
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import i18n from "@/i18n";
const pageKey = "signout";

@Component({
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class Signout extends Vue {
  created() {
    localStorage.setItem("token", "");
    this.$store.commit("setUser", null);
  }
}
